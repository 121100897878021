export const environment = {
  name: 'servefast-dev',
  production: true,
  useEmulators: false,
  useAnimations: true,
  wordManifestFileName: "manifest-dev.xml",
  featureFlags: {
    signInWithPopup: false,
  },
  baseUrl: 'https://dev.servefast.com/',
  apiBaseUrl: 'https://europe-west1-servefast-dev.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyBS-zOKeZoQ1aPxt80gtIch-ZIEza8hM4o',
    authDomain: 'dev.servefast.com',
    projectId: 'servefast-dev',
    storageBucket: 'servefast-dev.appspot.com',
    messagingSenderId: '953958422160',
    appId: '1:953958422160:web:bab4df88bb1849a9626a15',
    measurementId: 'G-FBT0CR9ZJY',
  },
  emulatorEndpoint: {
    authentication: 'localhost:9099',
    firestore: {
      host: 'localhost',
      port: 8080,
    },
    functions: {
      host: 'localhost',
      port: 5001,
    },
    storage: {
      host: 'localhost',
      port: 9199,
    },
    realtimeDatabase: {
      host: 'localhost',
      port: 9000,
    },
  },
  elastic: {
    appSearch: {
      clickThroughTags: ['word-addin'],
    },
  },
  dropbox: {
    appKey: 'v0nz04gfletdzut',
    authUrl: 'https://www.dropbox.com/oauth2/authorize',
    redirectUri: `https://dev.servefast.com/auth/integrations/dropbox`,
  },
  googleDrive: {
    clientId: '953958422160-8a03nuqec9rbfcd4plc2sjlgljj66jtd.apps.googleusercontent.com',
    authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    redirectUri: `https://dev.servefast.com/auth/integrations/google-drive`,
  },
  outlook: {
    clientId: '8e466c90-aca3-4f32-be24-bc1d1763525a',
    authUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
    redirectUri: `https://dev.servefast.com/auth/integrations/outlook`,
  },
  gmail: {
    clientId: '953958422160-8vudbk1qb1lkgt9iu6vbdhk1a6cv7daj.apps.googleusercontent.com',
    authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    redirectUri: `https://dev.servefast.com/auth/integrations/gmail`,
  },
  stripe: {
    publishableKey:
      'pk_test_51NImrCK1jI5g6VmMsFOoTBUqc45Wzy541RGS1gNAhmneO2a2h5et0B4nDT1PMckKtDPS5wVFzX0TVnERHfwpntpb00tirpoLN0',
  },
  microsoft: {
    tenant: '9f3ad614-da05-49ad-85e7-35e85e0bd773',
  },
};
